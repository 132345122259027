const tiempo = {
  getTextoHora:(value)=>{
  	switch(value){ 
      case '06:00:00': return '6:00 am'; break; 
      case '07:30:00': return '7:30 am'; break; 
      case '08:00:00': return '8:00 am'; break;
      case '09:00:00': return '9:00 am'; break; 
      case '09:30:00': return '9:30 am'; break;
      case '10:40:00': return '10:40 am'; break; 
      case '11:00:00': return '11:00 am'; break;
      case '12:10:00': return '12:10 pm'; break; 
      case '13:00:00': return '1:00 pm'; break;
      case '13:40:00': return '1:40 pm'; break; 
      case '15:10:00': return '3:10 pm'; break; 
      case '16:50:00': return '4:50 pm'; break; 
      case '18:20:00': return '6:20 pm'; break; 
      case '19:50:00': return '7:50 pm'; break; 
  	}
  	return value
  },
  VERSION : '0.5.8'
};

export default tiempo;