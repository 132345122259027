<template>
  <div class="container mt-3">
    <div v-if="clase">
      <h3 class="text-center">{{  titulo }}</h3>
      <h4 class="text-center cap">
        <span v-for="nombre in clase.nombresTipos">{{ nombre }} </span>
      </h4>
      <h5 class="text-center">{{ clase.fecha.split('T')[0] }} / {{ textoHora(clase.hora) }}</h5>
    </div>
    <div v-if="clase">
      <!--
      <div>
        <ClasesDetalleAgendamientoLab v-for="agendamiento in clase.agendamientos" :agendamiento="agendamiento"/>
      </div>
      -->
      <div>
        <ClasesDetalleAgendamiento @recargar="recargar" v-for="agendamiento in clase.agendamientos" :agendamiento="agendamiento"/>
      </div>
      
    </div>
    <hr>
    <button class="btn btn-primary btn-sm" @click="recargar">Recargar</button>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import ClasesDetalleAgendamiento from '@/components/Clases/ClasesDetalleAgendamiento';
  import ClasesDetalleAgendamientoLab from '@/components/Clases/ClasesDetalleAgendamientoLab';
  
  import utils from '@/modules/utils';

  export default {
    name: 'ClasesDetalle',
    components: {
      ClasesDetalleAgendamiento,
      ClasesDetalleAgendamientoLab
    },
    data () {
      return {
        
        clase_id:null
      }
    },
    props: {
      
    },
    mounted () {
      this.clase_id = this.$route.params.id;
      this.fetchClase({clase_id:this.clase_id})
    },
    methods:{
      ...mapActions({
        fetchClases: 'clases/fetchClases',
        fetchClase: 'clases/fetchClase',
        saveAsistencia: 'clases/saveAsistencia',
        saveObservacion: 'clases/saveObservacion',
      }),
      ...mapMutations({

      }),
      recargar(){
        this.fetchClase({clase_id:this.clase_id})
      },
      textoHora(value){
        return utils.getTextoHora(value)
      }
    },
    computed: {
      ...mapState({
        clases: state => state.clases.clases, 
      }),
      ...mapGetters({
      }),
      clase(){
        if(!this.clases){
          this.fetchClases()
          return null
        }
        let cl = this.clases.find(element =>{
          return element.id == this.clase_id
        })
        if(!cl){
          return null
        }
        return cl
      },
      titulo(){
        let titulo = ''
        if(this.clase.salon.numero){
          titulo += this.clase.salon.numero
        }
        titulo += this.clase.salon.nombre
        if(this.clase.curso){
          titulo += ' / '+this.clase.curso.nombre
        }        
        return titulo
      }
    }
  }
</script>

<style scope>
</style>